import React from "react";
import { Provider, teamsTheme, teamsDarkTheme, teamsHighContrastTheme, Loader, Flex, Dialog, Text, Button, Skeleton } from "@fluentui/react-northstar";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteList from "./routes";
import { setDimensionByDevice } from "./redux/actions/appConfigration";
import { app as microsoftTeams, authentication } from "@microsoft/teams-js";
import * as NFAUserAction from "./redux/actions/UserActions";
import { RootSchema } from "./redux/reducer";
import ErrorComponent from "./components/ErrorComponent";
import * as AppActions from "./redux/actions/appConfigration";
import { Constant, getQueryVariable } from "./constant";
import axios from "axios";
import DataNotFound from "./components/DataNotFound";
import AccessDenied from "./components/AccessDenied";
import LazyLoading from "./components/LazyLoading";

export interface IState {
  eMail: string;
  isOnTeams: boolean;
  app_env?: string;
  displayName: string;
}

// eslint-disable-next-line prefer-const
export let msTeamsEmail = "";

function App() {
  const [state, setState] = React.useState<IState>({
    eMail: "",
    isOnTeams: false,
    displayName: ""
  });
  const reduxState = useSelector((state: RootSchema) => state);
  const [theme, setTheme] = React.useState(teamsTheme);
  // const [idleTime, setIdleTime] = React.useState<{ isActive: boolean; isLoading: boolean; isError: boolean; }>({ isActive: false, isLoading: false, isError: false });

  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    theme: "colored",
  });

  microsoftTeams.initialize();

  const dispatch = useDispatch();

  const getNavigatorInfo = () => {
    const { userAgent } = navigator;
    if (userAgent.match(/Teams/gi)) setState({ ...state, isOnTeams: true });
    else setState({ ...state, isOnTeams: false });
  };

  const updateTheme = (themeStr?: string | null): void => {
    switch (themeStr) {
      case "dark":
        setTheme(teamsDarkTheme);
        break;
      case "contrast":
        setTheme(teamsHighContrastTheme);
        break;
      case "default":
      default:
        setTheme(teamsTheme);
    }
    //trackTrace(appInsights, TraceTelemtry.THEME, contextGet)
  };

  if (state.isOnTeams) {
    microsoftTeams.registerOnThemeChangeHandler((theme: string) => updateTheme(theme));
  }

  React.useEffect(() => {
    const value = {
      deviceWidth: window.innerWidth,
      deviceHeight: window.innerHeight
    };
    dispatch(setDimensionByDevice(value));
    getNavigatorInfo();
  }, [window.innerWidth]);

  //This should run on MS Teams context 
  React.useEffect(() => {
    microsoftTeams.getContext().then((context: microsoftTeams.Context) => {
      updateTheme(context.app.theme);
      console.log("Context", context);
      msTeamsEmail = context?.user?.userPrincipalName as string;
      setState({ ...state, eMail: context?.user?.userPrincipalName as string, displayName: context?.user?.displayName as string });
    }).catch(error => console.log("Error in Context", error));
  }, []);

  //This should run on browser only (Not in Ms Teams context) 
  //Make below function commented when deployed on Ms Teams (Dev or Prod)
  React.useEffect(() => {
    const email = getQueryVariable("email");
    if (email !== null && state.eMail === "") {
      setState({ ...state, eMail: email });
      msTeamsEmail = getQueryVariable("email") as string;
    }
  }, [state]);

  React.useEffect(() => {
    if (state.eMail.length > 0) {
      dispatch(NFAUserAction.getUserRolesListAction(state.eMail));
    }
  }, [state.eMail]);

  React.useEffect(() => {
    if (reduxState.userData.getUserRolesListData.isSuccess && reduxState.userData.getUserRolesListData.data.length && !reduxState.appConfig.userMainActivePosition.Position) {
      dispatch(AppActions.setMainActivePosition(reduxState.userData.getUserRolesListData.data[0]));
      // dispatch(AppActions.selectedPositionData([]));
    }
    else if (reduxState.userData.getUserRolesListData.isSuccess && reduxState.appConfig.userMainActivePosition.Position) dispatch(NFAUserAction.getUserPositionAction(reduxState.appConfig.userMainActivePosition.Role));
  }, [reduxState.userData.getUserRolesListData.isSuccess && reduxState.userData.getUserRolesListData.data.length, reduxState.appConfig.userMainActivePosition]);

  React.useEffect(() => {
    if (reduxState.userData.getUserPositionData.isSuccess && Array.isArray(reduxState.userData.getUserPositionData.data)) dispatch(AppActions.selectedPositionData(reduxState.userData.getUserPositionData.data));
  }, [reduxState.userData.getUserPositionData.isSuccess && Array.isArray(reduxState.userData.getUserPositionData.data)]);

  return (
    <Provider theme={theme} lang="en-US">
      <Skeleton animation="pulse">
        {reduxState.userData.getUserRolesListData.isSuccess && reduxState.userData.getUserPositionData.isSuccess ?
          Array.isArray(reduxState.userData.getUserRolesListData.data) && Array.isArray(reduxState.userData.getUserPositionData.data) ?
            reduxState.userData.getUserRolesListData.data.length > 0 || reduxState.userData.getUserPositionData.data.length > 0 ?
              <RouteList QueryData={state} /> : <DataNotFound />
            : <AccessDenied />
          : reduxState.userData.getUserRolesListData.isError || reduxState.userData.getUserPositionData.isError || (reduxState.userData.getUserRolesListData.isSuccess && reduxState.userData.getUserRolesListData.data.length === 0 || reduxState.userData.getUserPositionData.isSuccess && reduxState.userData.getUserPositionData.data.length === 0) ? <AccessDenied />
            : <LazyLoading />
        }
        {/* <Dialog open={idleTime.isActive} content={<Flex column gap="gap.medium" fill vAlign='center' hAlign="center" >
        <Text content="Your session expired" size='larger' weight='bold' color="brand" />
        <Text content="Please click the refresh button to continue" size='large' weight='semibold' />
        {idleTime.isError && <Text size='large' content={"Time out"} error weight="bold" />}
        <Button content={idleTime.isError ? "Reload" : "Refresh"} primary loading={idleTime.isLoading} disabled={idleTime.isLoading} onClick={() => idleTime.isError ? window.location.reload() : refreshToken()} />
      </Flex>} /> */}
      </Skeleton>
    </Provider>
  );
}

export default App;